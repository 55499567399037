var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"import-booking-page"}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Importar per","label-for":"importType"}},[_c('b-form-radio-group',{attrs:{"id":"importType","options":_vm.importTypeOptions},model:{value:(_vm.importType),callback:function ($$v) {_vm.importType=$$v},expression:"importType"}})],1)],1)],1),(
            _vm.importType === 'BOOKING_DATE' || _vm.importType === 'CHECKIN_DATE'
          )?_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Data d'inici","label-for":"startDate"}},[_c('b-form-datepicker',{attrs:{"id":"startDate","placeholder":"Data d'inici","locale":_vm.$i18n.locale,"start-weekday":1,"show-decade-nav":true,"date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },"hide-header":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),(_vm.importType === 'CHECKIN_DATE')?_c('b-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Data de fi","label-for":"endDate"}},[_c('b-form-datepicker',{attrs:{"id":"endDate","locale":_vm.$i18n.locale,"start-weekday":1,"show-decade-nav":true,"date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },"hide-header":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1):_vm._e()],1):_vm._e(),(_vm.importType === 'BOOKING_NUMBERS')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Localitzadors (una linia per cada localitzador)","label-for":"localizators"}},[_c('b-form-textarea',{attrs:{"id":"localizators","rows":"10"},model:{value:(_vm.localizators),callback:function ($$v) {_vm.localizators=$$v},expression:"localizators"}})],1)],1)],1):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-2 d-flex justify-content-center justify-content-sm-end"},[_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"light"},on:{"click":_vm.onCancelButtonClicked}},[_vm._v(" Cancel·la ")])],1),_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"primary","disabled":!_vm.startDate && !_vm.localizators},on:{"click":_vm.onImportButtonClicked}},[_vm._v(" Importa ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }