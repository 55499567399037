<template>
  <div id="import-booking-page">
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-2">
            <b-col cols="12">
              <b-form-group label="Importar per" label-for="importType">
                <b-form-radio-group
                  id="importType"
                  v-model="importType"
                  :options="importTypeOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="
              importType === 'BOOKING_DATE' || importType === 'CHECKIN_DATE'
            "
          >
            <b-col cols="12" sm="6" lg="4" xl="3">
              <b-form-group label="Data d'inici" label-for="startDate">
                <b-form-datepicker
                  id="startDate"
                  v-model="startDate"
                  placeholder="Data d'inici"
                  :locale="$i18n.locale"
                  :start-weekday="1"
                  :show-decade-nav="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  hide-header
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="importType === 'CHECKIN_DATE'"
              cols="12"
              sm="6"
              lg="4"
              xl="3"
            >
              <b-form-group label="Data de fi" label-for="endDate">
                <b-form-datepicker
                  id="endDate"
                  v-model="endDate"
                  :locale="$i18n.locale"
                  :start-weekday="1"
                  :show-decade-nav="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  hide-header
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="importType === 'BOOKING_NUMBERS'">
            <b-col cols="12">
              <b-form-group
                label="Localitzadors (una linia per cada localitzador)"
                label-for="localizators"
              >
                <b-form-textarea
                  id="localizators"
                  v-model="localizators"
                  rows="10"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="!startDate && !localizators"
          @click="onImportButtonClicked"
        >
          Importa
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormRadioGroup,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormDatepicker,
    BFormTextarea,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      localizators: null,
      importType: "BOOKING_DATE",
    };
  },
  computed: {
    importTypeOptions() {
      return [
        {
          text: "Data de reserva / modificació / cancel·lació",
          value: "BOOKING_DATE",
          selected: true,
        },
        { text: "Dates d'entrada", value: "CHECKIN_DATE", selected: true },
        { text: "Localitzadors", value: "BOOKING_NUMBERS", selected: true },
      ];
    },
  },
  watch: {
    importType() {
      this.resetInputs();
    },
    startDate(date) {
      if (!date) this.endDate = null;
      if (!this.endDate) this.endDate = date;
      if (date > this.endDate) this.endDate = date;
    },
  },
  methods: {
    resetInputs() {
      this.startDate = null;
      this.endDate = null;
      this.localizators = null;
    },
    onCancelButtonClicked() {
      this.$router.push({ name: "foravila-add-booking" });
    },
    onImportButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("bookings/importBookings", {
          startDate: this.startDate,
          endDate: this.endDate || this.startDate,
          localizators: this.localizators,
          importType: this.importType,
        })
        .then(() => this.$router.push({ name: "foravila-bookings-list" }))
        .catch(() =>
          notifyError(
            "Reserves no importada",
            "Les reserves no s'han pogut importar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
